import React, {useState} from 'react'
// import Image from 'gatsby-image'
import {Link} from 'gatsby'
import Helmet from 'react-helmet'
import PortableText from './portableText'
import Container from './container'

import styles from './page.module.css'

const Page = ({title, subTitle, _rawBody, pageSidebar, siteTitle, slug}) => {
  // console.log({pageSidebar})

  const [formValues, setFormValues] = useState([])

  const handleChange = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }

  return (
    <>
      <article className={styles.root} >

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
            <h4 className={styles.pagesubTitle}>{subTitle}</h4>
          </div>
          <div>
            <div className={styles.mainContent}>
              <div style={{paddingRight: '30px', width: '100%'}}>
                {_rawBody && <PortableText blocks={_rawBody} />}

              </div>
              {pageSidebar.length ? (
                <div className={styles.contentSideBar} >
                  <nav className={styles.sideBarNav}>
                    <ul>
                      { pageSidebar[0].links.map(link => (
                        <li key={link._key}>
                          {(link.siteLink.includes('https') || link.siteLink.includes('http')) ? (
                            <a href={link.siteLink} target='_blank' rel='noopener noreferrer'>
                              <span>{link.title}</span>
                              <span className={styles.subNavSubTitle}>{link.subTitle}</span>
                            </a>
                          ) : (
                            <Link to={link.siteLink}>
                              <span>{link.title}</span>
                              <span className={styles.subNavSubTitle}>{link.subTitle}</span>
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>

                  {pageSidebar[0].siteAddress ? (
                    <div>
                      <address>
                        <div className='vcard'>
                          <div className={styles.sectionTitle}>Address:</div>
                          <div className={`org ${styles.visuallyHidden}`}>{pageSidebar[0].siteAddress.companyName}</div>
                          <div className={styles.adr}>
                            <div className='street-address'>{pageSidebar[0].siteAddress.street}</div>
                            <span className='locality'>{pageSidebar[0].siteAddress.city}</span>,{' '}
                            <span className='region'>{pageSidebar[0].siteAddress.state}</span>{' '}
                            <span className='postal-code'>{pageSidebar[0].siteAddress.zip}</span>
                            <span className={`country-name ${styles.visuallyHidden}`}>United States</span>
                          </div>
                          <div className={styles.mapLink}>
                            <a href={pageSidebar[0].siteAddress.mapLink} target='_blank'>Map &amp; Directions</a>
                          </div>
                          <div className={styles.sectionTitle}>Call or Text:</div>
                          <div className={styles.tel}>
                            <a href={`tel:${pageSidebar[0].siteAddress.phone}`}>{pageSidebar[0].siteAddress.phone}</a>
                          </div>
                        </div>
                      </address>
                    </div>
                  ) : (
                    ''
                  )}

                  {pageSidebar[0].siteHours ? (
                    <div className={styles.topFooterContent}>
                      <div className={styles.sectionTitle}>Hours:</div>
                      <div className={styles.topFooterHours}>
                        <div>Mon: {pageSidebar[0].siteHours.monHours}</div>
                        <div>Tue: {pageSidebar[0].siteHours.tueHours}</div>
                        <div>Wed: {pageSidebar[0].siteHours.wedHours}</div>
                        <div>Thur: {pageSidebar[0].siteHours.thurHours}</div>
                        <div>Fri: {pageSidebar[0].siteHours.friHours}</div>
                        <div>Sat: {pageSidebar[0].siteHours.satHours}</div>
                        <div>Sun: {pageSidebar[0].siteHours.sunHours}</div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              ) : (
                ''
              )}

            </div>
          </div>
        </Container>
      </article>

    </>
  )
}
export default Page
